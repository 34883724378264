
:root {
    --site_color: #1c344c;
  }
.savings_new_table thead th {
    background-color: var(--site_color) !important;
    color: #fff !important;
    border: none !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 100% !important;
    white-space: nowrap;
    text-align: center !important;
}
.savings_new_table thead td {
    background-color: var(--site_color) !important;
    color: #fff !important;
    border: none !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 100% !important;
    white-space: nowrap;
}

tr.added-row > td {
    color: green !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}
tr.deleted-row > td {
    color: #ff3333 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}
tr.modified-row > td {
    color: orange !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}


.savings_new_table tbody td {
    background-color: #ECF4FF !important;
    color: #000;
    font-weight: 400;
    font-size: 12px;
    line-height: 100% !important;
    border-bottom: 0.37px solid #848484 !important;
    text-align: center !important;
    white-space: nowrap;
}
tr.savings-table-footer > td {
    background: var(--site_color) !important;
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 100% !important;
    border: none !important;
    white-space: nowrap;
    
}
.savings-table-footer{
    background: var(--site_color) !important;
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 100% !important;
    border: none !important;
    text-align: center !important;
}
span.ant-typography.css-dev-only-do-not-override-j0nf2s {
    color: #fff;
    text-align: center;
}

.plat-table-title-unrealised {
    position: absolute;
    font-size: 15.24px;
    font-weight: 500;
    margin-top: -53px;
    margin-left: 10px;
    background: #fff;
    padding: 0px;
    color: var(--site_color);
  }

tr.savings-table-footer-new > td {
    background: #AEC9EC !important;
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 100% !important;
    border: none !important;
    text-align: center !important;
    
}

.savings-table-footer td:first-child {
    border-bottom-left-radius: 10px;
}
.savings-table-footer td:last-child {
    border-bottom-right-radius: 10px;
}
.plat-saving-add-icon {
    float: right;
    color: var(--site_color);
    padding: 5px;
    cursor: pointer;
    font-size: 23px;
}
.plat-saving-table-body {
    margin-right: 20px;
}



.budget-table thead th {
    background-color: #f9fbff !important;
    color: #000 !important;
    border: none !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 100% !important;
}
.budget-table tbody td {
    background-color: #ECF4FF !important;
    color: #000 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 100% !important;
    text-align: center;
    border: 5px solid #f9fbff;
}
.budget-table tbody td .table-edit-list{
    background-color: #f9fbff !important;
    color: #000 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 100% !important;
    text-align: center;
    border: 5px solid #f9fbff;
}
td.ant-table-cell.table-edit-list {
    background-color: #f9fbff !important;
    color: #000 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 100% !important;
    text-align: center;
    border: 5px solid #f9fbff;
}

.potential-background {
    background-color: #98c1d9;
}
.realised-background {
    background-color: #8d99ae;
}
.identified-background {
    background-color: #e5e5e5;
}
.tracking-date {
    font-size: 14px;
}
.tracking-content{
    font-size: 14px;
}
.tracking-drawer-body {
    border-bottom: 1px solid #edeaea;
    padding: 10px;
}
.savings-body-height {
    height: 100vh;
}
.saving-model-table-title {
    position: absolute;
    font-size: 15.24px;
    font-weight: 500;
    top: -1.3%;
    left: 2%;
    background: #fff;
    padding: 0px;
    color: var(--site_color);
}
.saving-os-table-title {
    position: absolute;
    font-size: 15.24px;
    font-weight: 500;
    top: -3.7%;
    left: 2%;
    background: #fff;
    padding: 0px;
    color: var(--site_color);
}

.table-coloum-dots-icon {
    color: #fff;
}
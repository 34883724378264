.cams-header-body {
  padding: 1rem 1rem;
}

.header-title.header-color {
  color: #1c344c;
  font-size: 22px;
}
.cams-content {
  background: #f8f8f8;
}
.cams-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  background: transparent;
}
.header-filter {
  position: relative;
}
.customer-select {
  width: 11.5rem;
}
.spinner-class {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reports-submit {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  background-color: #1c344c;
  color: white;
}

.customer-filter {
  width: 10rem;
  position: absolute;
  top: 4.5rem;
  right: 2rem;
  z-index: 1;
}
.ant-tabs-nav-list {
  border: 0;
}
.ant-tabs-tab {
  margin: 0px !important;
  background: transparent !important;
}
.ant-tabs-tab.ant-tabs-tab-active {
  background: transparent !important;
}
.ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #1c344c !important;
  font-weight: 600;
}
.cams-tabs .ant-tabs-tab-btn {
  color: #848484 !important;
  padding: 0rem 3rem;
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: #1c344c !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px;
}
.alert-detail-table {
  padding: 0.8rem 0rem;
}
.alert-detail-table .table > :not(caption) > * > *,
.summary-view-table .table > :not(caption) > * > * {
  padding: 0.3rem 0.8rem;
}
.alert-detail-table .table,
.summary-view-table .table {
  /* border: 1px solid #1c344c; */
  border-radius: 5px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  font-size: 12px;
}
.top-filter {
  padding-top: 0.3rem;
}
.top-filter-input {
  width: 100%;
  /* border: 1px solid #d2d2d2;
  border-radius: 5px;
  padding: 0.3rem 1rem; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}

.alert-detail-table > .react-bootstrap-table {
  height: 350px;
  overflow-y: auto;
  width: 591px;
}

.alert-detail-table-reports > .react-bootstrap-table {
  height: 350px;
  overflow-y: auto;
  margin-top: 1rem;
  /* width: auto; */
}

.alert-detail-table-reports .table > :not(caption) > * > * {
  padding: 0.3rem 0.8rem;
}
.table-section {
  overflow-y: hidden;
}
.summary-view-table > .react-bootstrap-table {
  height: 200px;
  overflow-y: auto;
}

.react-bootstrap-table th {
  position: sticky;
  top: 0;
}

.react-bootstrap-table tfoot {
  position: sticky;
  bottom: 0;
}
span.alert-info {
  font-size: 10px;
  background-color: transparent;
  color: black;
}
.card-value-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.summary-view .plat-card-1 {
  height: 10.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plat-card-table {
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
}

.alert-detail-table table > thead {
  background: #1c344c;
  color: white;
  position: sticky;
  top: 0;
}

.alert-detail-table2-height {
  /* height: 300px; */
  overflow-y: auto;
}
.reports-table {
  display: block !important;
  width: 100%;
}
.export {
  cursor: pointer;
  margin: 1.5rem 0rem 1rem 1rem;
}

.export span {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
  padding: 0.4rem 1rem;
}

.info-icon-position {
  position: absolute;
  right: 2em;
  top: 7.2em;
  z-index: 1;
  /* margin-top: 15px !important; */
  margin-right: -1% !important;
}
.reports-spinner {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 21rem;
}
.circle-critical,
.circle-warn,
.circle-unreachable,
.circle-unknown,
.circle-up,
.circle-down,
.circle-ok {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
.circle-critical {
  background-color: #ed7d31;
}

.circle-warn {
  background-color: #be1e2d;
}

.circle-unreachable {
  background-color: #0e365a;
}
.circle-unknown {
  background-color: #848484;
}
.circle-up {
  background-color: #a5e495;
}
.circle-ok {
  background-color: #ecc94d;
}
.circle-down {
  background-color: #e3818a;
}
.page-item.active .page-link {
  background-color: #1c344c !important;
  z-index: 0 !important;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.2s linear;
}
.summary-info-icon-position {
  position: absolute;
  right: 4em;
  top: 4.2em;
  z-index: 1;
}

.icon-div {
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: 1rem;
  border-radius: 5px;
  width: auto;
  padding: 0.2rem;
}
.cams-date {
  width: 12rem;
  margin-right: 0.5rem;
}
.icon-shadow {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.zoho-popup {
  width: 50%;
  margin-left: 22rem;
}
.zoho-info {
  color: #082648;
  margin-top: 1rem;
}
.alert-text {
  float: right !important;

  color: #082648;

  font-size: 0.7rem;
}

.smart-alert-text {
  font-size: 0.75rem !important;

  cursor: pointer !important;
}
@media only screen and (max-width: 600px) {
  .ant-tabs-tab-btn {
    padding: 0rem 1rem;
  }
  .header-title.header-color {
    color: #1c344c;
    font-size: 14px;
  }
  .summary-view .plat-card-1 {
    height: 8rem;
  }
}

.expand-button {
    position: relative;
  }

.instanceTrackingTable tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

.instanceTrackingTable thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

.instanceTrackingTable tbody td:nth-child(5) {
    position: sticky;
    right: 0;
    z-index: 2;
}
.instanceRuntimeTable thead th:first-child {
    color: #4f4f4f !important;
   pointer-events: none;
}

.instanceTrackingTable thead th:nth-child(5) {
    position: sticky;
    right: 0;
    z-index: 2;
}

.instanceRuntimeTable tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

.instanceRuntimeTable thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

.instanceRuntimeTable tbody td:nth-child(9) {
    position: sticky;
    right: 0;
    z-index: 2;
}

.instanceRuntimeTable thead th:nth-child(9) {
    position: sticky;
    right: 0;
    z-index: 2;
}

.instanceRuntimeTable tbody td:nth-child(2) {
   display: none;
}

.instanceRuntimeTable thead th:nth-child(2) {
   display: none;
}
.plat-home-logo {
  padding: 10px;
}
.plat-sign-img-group {
  position: relative;
  margin-left: 100px;
}
.plat-signup-main-image-info {
  position: absolute;
  top: 2px;
  left: 394px;
}
.plat-sign-login-tap {
  background: #f5faff;
  border-radius: 5px;
  margin-right: 19px;
  height: 100%;
  /* height: 25rem; */
  overflow-y: scroll;
}

.termsAndConditionText {
  font-size: 0.75rem !important;
}

.termsAndConditionText .form-check-input[type="checkbox"] {
  margin-right: 0rem !important;
  width: 1.1rem !important;
  height: 1.15rem !important;
  margin-top: 1px;
  margin-left: 1%;
}

/* form-check-input */

.plat-sign-login-tap::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.required:after {
  content: " *";
  color: red;
}

.plat-signup-image-info {
  width: 200px;
}
a.nav-link.plat-sign-link {
  position: inherit;
  width: auto;
}
.plat-sign-tabs {
  padding: 10px 0px !important;
  justify-content: center;
}
a.nav-link.plat-sign-link {
  position: inherit;
  width: auto;
  border-bottom: 2px solid #1b5f9d;
  font-size: 13px;
  font-weight: bold;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  /* color: #1b5f9d !important;
  border-color: #1b5f9d !important; */
  color: #082648 !important;
  border-color: #082648 !important;
  background: #f5faff !important;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #616161;
  /* background-color: #fff; */
  border-color: #e0e0e0 #e0e0e0 #fff;
}
.plat-signgroup-right-body {
  /* padding: 0px 68px; */
  padding: 0px 20px;
}

.loginInputField {
  height: 1.85rem !important;
  font-size: 1.25rem;
  width: 100%;
}

.plat-form-label {
  padding: 2px;
  font-size: 0.75rem;
}

.loginText {
  font-size: 0.75rem;
}

.sign-page-link {
  text-decoration: underline !important;
  color: #1b5f9d;
  font-size: 0.75rem;
  font-weight: 500;
}
.plat-loing-btn-body {
  text-align: center;
}
.plat-login-button {
  color: #fff !important;
  background: #082648 !important;
  /* background: #1b5f9d !important; */
}
.plat-login-azure-btn {
  width: 100%;
  margin-bottom: 1rem;
}
.bg-signin {
  background: #dbe2e9;
  position: relative;
}

.login-bg {
  background: #dbe2e9;
  height: 44rem;
  position: relative;
  z-index: 0;
}
.left-img {
  z-index: -1;
  position: absolute;
  height: 28rem;
  left: -8%;
  top: 1%;
}
.left-text {
  z-index: 2;
  position: absolute;
  bottom: 20%;
  left: 6%;
}
.text-right {
  text-align: right !important;
}
.yellow-background {
  height: 35rem;
  right: 0%;
  position: absolute;
  width: 60rem;
  /* z-index: 2; */
}
.login-azure-ad-model .ant-modal-title {
  text-align: center;
}
/* media query */
@media (max-width: 1440px) {
  .plat-sign-tabs {
    padding: 10px 0px !important;
    justify-content: center;
  }
}
@media (max-width: 1024px) {
  .plat-sign-img-group {
    position: relative;
    margin-left: 0px;
  }
  .plat-signup-image {
    width: 417px;
  }
  .plat-signup-main-image-info {
    position: absolute;
    top: 15px;
    left: 298px;
  }
  .plat-signup-image-info {
    width: 139px;
  }
  .plat-sign-tabs {
    padding: 10px 0px !important;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .plat-sign-tabs {
    padding: 10px 0px !important;
    justify-content: center;
  }
}

@media (max-width: 426px) {
  .plat-signup-image {
    width: 380px;
  }
  .plat-signup-main-image-info {
    position: absolute;
    top: 4px;
    left: 273px;
  }
  .plat-sign-tabs {
    padding: 10px 0px !important;
    justify-content: center;
  }
  .plat-sign-login-tap {
    background: #f5faff;
    border-radius: 5px;
    margin-top: 10px !important;
  }
}
@media (max-width: 376px) {
  .plat-signup-image {
    width: 322px;
  }
  .plat-signup-main-image-info {
    position: absolute;
    top: -6px;
    left: 228px;
  }
  .plat-sign-tabs {
    padding: 10px 0px !important;
    justify-content: center;
  }
}
@media (max-width: 320px) {
  .plat-sign-tabs {
    padding: 10px 0px !important;
    justify-content: center;
  }
}

p.para-text-1 {
  color: #07182b;
  font-weight: 400;
  font-size: 18px;
}

p.para-text-2 {
  color: #082648;
  font-weight: 600;
  font-size: 18px;
}

p.para-text-2 span {
  background: url("../../assets/images/yellow-highlighter.png") no-repeat;
  padding: 1rem 0rem;
  background-size: 18rem 4rem;
}

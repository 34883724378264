.ticket_dashbaord_title {
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: #1B5F9D;
    margin-top: 25px;
}
.operation_compliance .ant-tabs-nav-list{
    background-color: #fff !important;
}
.operation_compliance .ant-tabs-tab{
    margin-bottom: 0px !important;
    background-color: #fff !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    margin-right: 10px !important;
}
.operation_compliance .ant-tabs-tab-active{
    background-color: #d9e9ff !important;
    color: #000 !important;
    box-shadow: none !important;
}
.operation_compliance .ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #000 !important;
}
.operation_compliance .ant-tabs-nav-wrap{
    border-bottom: 0.5px solid #e6dfdf;
}

.path-management-title {
    font-size: 18px;
    text-align: center;
}
.path-management-div {
    position: relative;
}

.path-management-title:before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    top: 90%;
    margin-left: -14px;
    background-color: #1b5f9d;
}
.path-management-title:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 9px;
    left: 50%;
    margin-left: -50px;
    background-color: #1b5f9d;
}
.path-chart-header {
    margin-top: 50px;
}
.path-management-border {
    border-right: 1px solid #e0e0e0;
}


@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  /* --costimize_site_color: #433f3f; */
  --costimize_site_color: #141416;
  --costimize_background_color: #f8f8f8;
  --costimize_lite_site_color: #e7e7e7;
  --white-color: #fff;
}
* {
  font-family: "Poppins" !important;
}
.white-color {
  color: #ffffff !important;
}
.site-color {
  color: var(--costimize_site_color);
}
.plat-text-center {
  text-align: center !important;
}
.plat-text-left {
  text-align: left;
}
.plat-text-right {
  text-align: right;
}
.plat-padding-right-1 {
  padding-right: 1rem;
}
.costimize_bg {
  background-color: var(--costimize_background_color);
  height: 100% !important;
}
.danger-color {
  color: #be1e2d;
}
.success-color {
  color: #22bb33;
}
.costimize-loader .ant-spin .ant-spin-dot-item {
  background-color: var(--costimize_site_color) !important;
}
.costimize-body-screen {
  margin-top: 7.5rem;
  margin-left: 4.6rem;
  padding: 0px 2.3rem;
  width: 100%;
  margin-bottom: 5rem;
}
.savings-data-not-found {
  font-size: 24px;
  display: flex;
  padding: 58px 0px;
  justify-content: center;
}
.costimize-header-body {
  width: 100%;
  position: fixed;
  margin-left: 4.6rem;
  padding: 0 6.9rem 0 2.3rem;
  z-index: 1;
  background-color: var(--costimize_background_color);
}
.chart-space > .ant-card-body {
  margin: 0px !important;
  padding: 22px 0px 0px !important;
}
.costimize-sidebar {
  height: 100vh !important;
  position: fixed !important;
  border: none !important;
}
.costimize_header_logo {
  width: 145px;
  height: 29px;
  margin-top: 4px;
}
.costimize-sidebar > .ps-sidebar-container {
  background-color: var(--costimize_site_color) !important;
  padding-top: 16px;
  color: #fff;
}
.mobile-costimize-sidebar > .ps-sidebar-container {
  background-color: var(--costimize_site_color) !important;
  padding-top: 70px;
  color: #fff;
}
.costimize-sidebar > .ps-sidebar-container > .ps-menu-root {
  font-size: 20px !important;
}
.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root {
  height: 65px !important;
}
.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button {
  margin-top: 6px;
}
.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button:hover {
  color: var(--costimize_site_color) !important;
  background-color: var(--costimize_background_color);
}
.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active {
  background-color: var(--costimize_background_color) !important;
  color: var(--costimize_site_color) !important;
  position: relative;
}
.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root:hover {
  background-color: var(--costimize_background_color) !important;
  color: var(--costimize_site_color) !important;
  position: relative;
}
/* .sidebar-budget-without-white:hover{
    display: none;
}
.sidebar-budget-with-white:hover{
    display: block;
} */
.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -46px;
  height: 46px;
  width: 16px;
  left: 48px;
  border-top-right-radius: 25px;
  box-shadow: 0 -25px 0 0 var(--costimize_background_color);
}
.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 65px;
  height: 46px;
  width: 16px;
  left: 48px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 25px 0px 0px var(--costimize_background_color);
}
.sidemenu-toggle {
  background: var(--costimize_site_color);
  color: #fff;
  width: 30px;
  height: 30px;
  padding: 3px;
  border-radius: 7px;
}
.header-title {
  font-weight: 600;
  font-size: 22px;
}
.header-logo-block {
  text-align: end;
}
.header-filter-block {
  text-align: end;
}
.header-filter-list {
  padding: 0px !important;
}
.header-filter-list li {
  display: inline;
  padding: 0;
  margin: 0 0 0 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12.24px;
  color: var(--costimize_site_color);
}
.header-filter-list li.active {
  border-bottom: 2px solid #433f3f;
}
.header-filter-btn {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 7px;
  padding: 10px;
  color: var(--costimize_site_color) !important;
}
.header-filter-btn-active {
  background: #e7e7e7 !important;
}
.filter-dropdown-render {
  width: 200px;
  background-color: var(--costimize_lite_site_color);
  border-radius: 11px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.05) 0px 9px 28px;
}
.filter-dropdown-scroll {
  height: 550px !important;
  overflow-y: scroll !important;
}
.filter-menu-dropdown-render {
  background-color: #fff;
  width: 182px;
  border-radius: 11px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.05) 0px 9px 28px;
}
.budget-table-top-icon {
  padding: 10px;
}
.plat-header-filter {
  width: 200px;
  z-index: 2 !important;
  position: fixed !important;
}
.plat-filter-menu-list {
  z-index: 2 !important;
}
.plat-header-filter ul {
  background-color: var(--costimize_lite_site_color) !important;
}
.plat-header-filter ul li:hover {
  background-color: var(--costimize_lite_site_color) !important;
  cursor: default !important;
}
.filter-header {
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: space-between;
}
.filter-dropdown-render > .ant-space {
  width: 100%;
}
.filter-header-right {
  font-size: 11px;
  font-weight: 400;
  padding-top: 1px;
}
.filter-header-left {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}
.filter-sub-title {
  font-weight: 500;
  font-size: 11.24px;
  line-height: 14px;
  text-align: center;
}
.block-title {
  font-weight: 600;
  font-size: 17px;
}
.text-justify {
  text-align: justify;
}
.block-title-20 {
  font-weight: 600;
  font-size: 20px;
}

.plat-menu-list {
  padding: 0px 0px 0px 8px;
  margin: 0rem;
  font-size: 12px;
}
.header-menu-filter-btn {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  color: #000;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  padding: 6px;
  display: flex;
  justify-content: space-between;
}
.filter-menu-dropdown-render .ant-dropdown-menu {
  /* position: absolute; */
  /* top: 35px; */
  width: 100%;
  height: 100px;
  overflow-y: scroll;
}
.menu-filter-search {
  /* position: absolute; */
  width: 100%;
  top: 0;
}
/* card style */
.plat-card-1 {
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
  height: 100%;
}
.card-title-1 {
  font-weight: 400;
  font-size: 16.8249px;
  line-height: 25px;
  color: #848484;
  text-align: center;
}
.card-value-1 {
  font-weight: 500;
  font-size: 23.9141px;
  line-height: 36px;
  text-align: center;
}
.plat-card-1 .ant-card-body {
  padding: 12px;
}
.card-bottom-content {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  padding: 0px;
  margin: 0px;
}
.card-1-bottom-value {
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  color: #433f3f;
  margin: 0;
}
.card-1-bottom-flex {
  display: flex;
  justify-content: space-evenly;
}
.plat-card-2 {
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
  height: 100%;
}
.plat-card-2-1 {
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
  height: 100%;
}
.card-2-title {
  font-weight: 400;
  font-size: 13.9504px;
  line-height: 18px;
  color: #848484;
  text-align: center;
}
.card-2-value {
  font-weight: 500;
  font-size: 18.9857px;
  line-height: 25px;
  color: #222222;
  text-align: center;
  margin: 0rem;
}
.plat-card-2 .ant-card-body {
  padding: 20px;
}
.plat-card-2-1 .ant-card-body {
  padding: 30px;
}
.plat-card-3 {
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
  height: 100%;
}
.plat-card-3 .ant-card-body {
  margin: 0px !important;
  padding: 0px !important;
  height: 100px !important;
}
.tagging-relative {
  position: relative;
}
.tagging-absolute {
  position: absolute;
  top: -18px;
  right: 4px;
}
.costimize-collapse {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
}
.constimize-event-list {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #433f3f;
  padding: 6px;
}
.constimize-event-title {
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  color: #433f3f;
}
.spend-chart-title {
  font-weight: 500;
  font-size: 15.24px;
  line-height: 100%;
  margin-bottom: 0px !important;
  margin-top: 0.7rem;
  text-align: center !important;
}
.spend-day-chart-title {
  font-weight: 400;
  font-size: 16.8249px;
  line-height: 25px;
  margin-bottom: 0px !important;
  margin-top: 0.3rem;
  text-align: left !important;
  color: #848484;
}
.spend-day-chart-content {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  padding: 0px;
  margin-top: 6px;
}
.quick-glance-icon {
  margin: -2px 6px 0px;
  font-size: 20px;
}
.pa-quick-glance-icon {
  margin: 14px;
  font-size: 30px;
}
.chart-filter {
  position: absolute;
  top: -7px;
  right: 4px;
  font-size: 17px;
  color: #000;
}
.chart-filter2 {
  position: absolute;
  top: -9px;
  right: 12px;
  font-size: 17px;
  color: #000;
}
.plat-padding {
  padding: 7px;
}
.card-1-title-2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  margin: 0rem;
}
.costimize-center-icon {
  width: 24px;
  height: 24px;
  margin: 1.5rem;
}
.card-1-value-2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
}
/* asset */
.plat-icon-bg {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 8px;
  padding: 1px 9px;
  width: 40px;
  height: 27px;
  margin: 0 8px;
}
.spend-search {
  display: flex;
  position: absolute;
  right: 0;
  z-index: 1;
}
.spend-asset-body {
  /* position: relative; */
  display: block;
}
.tagging-p-title {
  font-size: 20px;
  text-align: left;
}
.tagging-p-value {
  margin: 0;
  text-align: left;
}
.tagging-p-icon {
  cursor: pointer;
  margin: 2px;
}
/* tab */
.costimize-table-tab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  background: var(--costimize_background_color) !important;
}
span.ant-table-column-sorter-inner {
  color: #b0a8a8 !important;
}
span.anticon.anticon-caret-down.ant-table-column-sorter-down.active {
  color: #fff !important;
}
span.anticon.anticon-caret-up.ant-table-column-sorter-up.active {
  color: #fff;
}
.costimize-bootstrap-table thead th label.filter-label {
  display: block !important;
}
.costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  background-color: var(--costimize_background_color) !important;
  color: #848484 !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 100% !important;
  padding: 13px 15px !important;
  border: none !important;
}
.costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  > .ant-tabs-tab-btn {
  color: var(--costimize_site_color) !important;
}
.costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  background: var(--costimize_site_color) !important;
}
.costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active {
  color: var(--costimize_site_color) !important;
}
/* assets tab */
.assetspend-costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list {
  background: var(--costimize_background_color) !important;
}
.assetspend-costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  background-color: var(--costimize_background_color) !important;
  color: #848484 !important;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  padding: 13px 15px !important;
  border: none !important;
}
.assetspend-costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  > .ant-tabs-tab-btn {
  color: var(--costimize_site_color) !important;
}
.assetspend-costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  background: var(--costimize_site_color) !important;
}
.assetspend-costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active {
  color: var(--costimize_site_color) !important;
}
.assent-spend-head {
  background: var(--costimize_site_color);
  padding: 18px 0px;
  color: #fff;
  border-top-left-radius: 11.1579px;
  border-top-right-radius: 11.1579px;
  position: sticky;
}
.plat-asset-spend-body-vs {
  /* margin-top: 26px; */
}
.asset-spend-title-vs {
  font-weight: 600;
  font-size: 12.24px;
  line-height: 18px;
  padding-left: 16px;
  display: flex;
  align-items: center;
}
.all-environment-icon {
  position: absolute;
  top: 21px;
  left: 7px;
  height: 100%;
  display: flex;
  align-items: center;
}
.environment-list-icon {
  position: absolute;
  top: 24px;
  left: 2px;
  height: 100%;
  display: flex;
  align-items: center;
}
.cloud-list-icon {
  position: absolute;
  top: 24px;
  left: 2px;
  height: 100%;
  display: flex;
  align-items: center;
}
.application-list-icon {
  position: absolute;
  top: 22px;
  left: 40px;
  height: 100%;
  display: flex;
  align-items: center;
}
.plat-chart-center {
  display: flex;
  justify-content: center !important;
}
.asset-spend-values-vs {
  font-weight: 400;
  font-size: 12.24px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.asset-spend-border-bottom-vs {
  border-bottom: 0.37px solid #848484;
  width: 100% !important;
}
.cd-accordion--animated-vs {
  border-radius: 12px;
  margin: 0px !important;
  padding: 0px !important;
  background: #fff !important;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
}
.costimize-asset-spend-scroll {
  position: sticky;
  top: 143px;
  z-index: 1;
}
.apexcharts-legend-series {
  text-align: left !important;
}
.assent-spend-footer {
  height: 1rem;
}
.popup-chart-cart {
  background: #ffffff;
  box-shadow: 0px 3.78126px 3.78126px rgba(217, 217, 217, 0.6);
  border-radius: 10.5477px;
}
.details-recommendation-title {
  text-align: center;
  font-weight: 500;
  font-size: 13.0495px;
  line-height: 20px;
}
.details-recommendation-list {
  text-align: left;
  display: flex;
  justify-content: space-between;
}
ul.details-rec-right {
  display: flex;
}
.details-rec-right li {
  padding: 7px;
}
.recom-table {
  width: 100%;
  border-collapse: collapse;
}
.recom-table td,
th {
  border: none;
}
/* table */
.costimize-bootstrap-table thead th {
  background-color: var(--costimize_site_color) !important;
  color: #fff !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  /* text-align: center !important; */
  z-index: 0 !important;
}
.costimize-savings-table-footer {
  background: var(--costimize_site_color) !important;
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  border: none !important;
  text-align: center !important;
}
.costimize-savings-table-footer
  .ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
  background: var(--costimize_site_color) !important;
}
.costimize-savings-table-footer
  .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  background: var(--costimize_site_color) !important;
}
.costimize-bootstrap-table thead th .order > .dropup > .caret {
  margin: 10px 0;
  color: #fff;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.costimize-bootstrap-table thead th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #fff;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
}
.costimize-bootstrap-table
  thead
  th
  > .react-bootstrap-table-sort-order.dropup
  > .caret {
  margin: 10px 6.5px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: none !important;
}
.costimize-bootstrap-table
  thead
  th
  > .react-bootstrap-table-sort-order
  > .caret {
  margin: 10px 6.5px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
}
.costimize-bootstrap-table thead td {
  background-color: var(--costimize_site_color) !important;
  color: #fff !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  z-index: 0 !important;
}
/* .filter.number-filter {
    display: flex;
}
.filter.date-filter {
    display: flex;
}
select#date-filter-comparator-date {
    width: 38px !important;
}
select#number-filter-comparator-potential_savings {
    width: 38px !important;
} */

.custimize-drop-down-list .ant-dropdown-menu {
  background-color: var(--costimize_site_color) !important;
  color: #fff !important;
  width: 162px !important;
}

.custimize-drop-down-list .ant-dropdown-menu .ant-dropdown-menu-item {
  color: #fff !important;
}
.custimize-drop-down-list.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item-divider {
  background-color: #fff !important;
}

.slm-drop-down-list .ant-dropdown-menu {
  background-color: #1c344c !important;
  color: #fff !important;
  width: 162px !important;
}

.slm-drop-down-list .ant-dropdown-menu .ant-dropdown-menu-item {
  color: #fff !important;
}
.slm-drop-down-list.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item-divider {
  background-color: #fff !important;
}

.costimize-bootstrap-table tbody td {
  background-color: #fff !important;
  color: #000;
  font-weight: 400;
  font-size: 12px;
  line-height: 100% !important;
  border-bottom: 0.37px solid #848484 !important;
  text-align: left;
  white-space: nowrap;
  border: none;
  z-index: 0 !important;
}
.costimize-bootstrap-table tfoot th {
  background-color: var(--costimize_site_color) !important;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 100% !important;
  text-align: left !important;
  white-space: nowrap;
  border: none !important;
}
.custom-sort-up .react-bootstrap-table-sort-order,
.custom-sort-down .react-bootstrap-table-sort-order {
  color: #fff !important;
}
.costimize-bootstrap-table tfoot > tr:first-child > *:first-child {
  border-end-start-radius: 8px !important;
}
.costimize-bootstrap-table tfoot > tr:first-child > *:last-child {
  border-end-end-radius: 8px !important;
}
.react-bs-table-sizePerPage-dropdown {
  display: none;
}
.costimize-table-width
  > .react-bootstrap-table-pagination
  > .react-bootstrap-table-pagination-list
  > .react-bootstrap-table-page-btns-ul
  > .active
  > .page-link {
  background-color: var(--costimize_site_color) !important;
}
.table-bordered {
  border: none;
}
.number_right {
  text-align: right !important;
  float: right !important;
}
.table-bordered td,
.table-bordered th {
  border-top: none;
  border-bottom: none;
}
.costimize-bootstrap-table thead > tr:first-child > *:first-child {
  border-start-start-radius: 8px !important;
}
.costimize-bootstrap-table thead > tr:first-child > *:last-child {
  border-start-end-radius: 8px !important;
}
/* .costimize-bootstrap-table .ant-pagination {
  position: sticky;
  bottom: 0;
} */
.costimize-table-width {
  overflow-x: auto;
  width: 90vw;
}
.table-add-button {
  border: none;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(43, 38, 38, 0.25);
  border-radius: 4px;
  margin: 6px;
  padding: 7px 24px;
}
.drawer-spend-invoice {
  font-weight: 600;
  font-size: 19px;
}
/* tagging */
.tagging-pop-ul-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #848484;
}
.tagging-popup-body {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
  padding: 21px 0px;
  font-weight: 500;
  font-size: 10px;
  cursor: pointer;
}
.tagging-popup-sig-arrow {
  font-size: 23px;
}
.tagging-popup-service {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tagging-category-popup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tagging-popup-button {
  color: #000 !important;
}
.taggin-pop-btu {
  background: #fff !important;
  margin: 0 2px;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 7px;
}
.popup-delete-button {
  color: red !important;
}
.tagging-update-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  text-align: left;
}
span.ant-dropdown-trigger.ant-table-filter-trigger.ant-dropdown-open {
  color: #fff;
}
span.ant-dropdown-trigger.ant-table-filter-trigger {
  color: #fff;
}
.spend-day-back-btu {
  font-size: 21px;
  border: 1px solid #000;
  padding: 2px;
  margin-right: 7px;
  margin-top: 5px;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.costdemaze_switch {
  background: var(--costimize_site_color) !important;
}
.red-round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
  margin-left: 7px;
}
.yellow-round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fcaf04;
  margin-left: 7px;
}
.ant-drawer-title {
  font-size: 22px !important;
  font-weight: 600 !important;
}
.green-round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #008000;
  margin-left: 7px;
}
.yellow-round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #c30;
  margin-left: 7px;
}
.lite-red-round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ff8383;
  margin-left: 7px;
}
.instance-title {
  font-size: 17px;
  font-weight: 600;
}
/* media query */
@media only screen and (max-width: 2560px) and (min-width: 2305px) {
}
@media only screen and (max-width: 2304px) and (min-width: 2161px) {
}
@media only screen and (max-width: 2160px) and (min-width: 1922px) {
}
@media only screen and (max-width: 1921px) and (min-width: 1681px) {
}
@media only screen and (max-width: 1680px) and (min-width: 1601px) {
}
@media only screen and (max-width: 1600px) and (min-width: 1471px) {
}
@media only screen and (max-width: 1470px) and (min-width: 1441px) {
}
@media only screen and (max-width: 1440px) and (min-width: 1367px) {
}
@media only screen and (max-width: 1366px) and (min-width: 1281px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1081px) {
}
@media only screen and (max-width: 1080px) and (min-width: 1025px) {
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
}
@media only screen and (max-width: 768px) and (min-width: 427px) {
  .costimize-header-body {
    width: 100%;
    position: fixed;
    margin-left: 0rem;
    padding: 0 3.9rem 0 3.5rem;
    background-color: var(--costimize_background_color);
  }
  .header-filter-block {
    text-align: center;
  }
  .sidebar-mobile-main {
    z-index: 3;
    position: fixed;
    padding: 12px;
    margin-top: 0px;
  }
  .costimize-body-screen {
    margin-top: 7.5rem;
    margin-left: 0.7rem;
    padding: 0px 3.3rem;
    width: 100%;
  }
  .plat-card-3 .ant-card-body {
    margin: 0px !important;
    padding: 0px !important;
    height: 172px !important;
  }
}
@media only screen and (max-width: 426px) and (min-width: 377px) {
  .costimize-header-body {
    width: 100%;
    position: fixed;
    margin-left: 0rem;
    padding: 0 3.9rem 0 3.5rem;
    background-color: var(--costimize_background_color);
  }
  .sidebar-mobile-main {
    z-index: 3;
    position: fixed;
    padding: 12px;
    margin-top: 0px;
  }
  .costimize-body-screen {
    margin-top: 10.5rem;
    margin-left: 0.7rem;
    padding: 0px 3.3rem;
    width: 100%;
  }
  .header-filter-block {
    text-align: center;
  }
  .plat-card-3 .ant-card-body {
    margin: 0px !important;
    padding: 0px !important;
    height: 172px !important;
  }
}
@media only screen and (max-width: 376px) and (min-width: 321px) {
  .costimize-header-body {
    width: 100%;
    position: fixed;
    margin-left: 0rem;
    padding: 1rem 1.5rem 0;
    background-color: var(--costimize_background_color);
  }
  .sidebar-mobile-main {
    z-index: 3;
    position: fixed;
    padding: 12px;
    margin-top: 53px;
  }
  .costimize-body-screen {
    margin-top: 10.5rem;
    margin-left: 0.7rem;
    padding: 0px 1.3rem;
    width: 100%;
  }
  .header-filter-block {
    text-align: center;
  }
  .plat-card-3 .ant-card-body {
    margin: 0px !important;
    padding: 0px !important;
    height: 172px !important;
  }
}
@media (max-width: 320px) {
  .costimize-header-body {
    width: 100%;
    position: fixed;
    margin-left: 0rem;
    padding: 1rem 1.5rem 0;
    background-color: var(--costimize_background_color);
  }
  .sidebar-mobile-main {
    z-index: 3;
    position: fixed;
    padding: 12px;
    margin-top: 51px;
  }
  .costimize-body-screen {
    margin-top: 10.5rem;
    margin-left: 0.7rem;
    padding: 0px 1.3rem;
    width: 100%;
  }
  .header-title {
    font-weight: 600;
    font-size: 18px;
    margin-top: 5px;
    /* text-align: center; */
  }
  .header-logo-block {
    text-align: center;
  }
  .plat-card-3 .ant-card-body {
    margin: 0px !important;
    padding: 0px !important;
    height: 172px !important;
  }
  /* .header-filter-block {
        text-align: center;
    } */
  /* .header-filter-list li {
        display: block;
        padding: 0;
        margin: 0 0 0 20px;
        cursor: pointer;
        font-weight: 500;
        font-size: 12.24px;
        color: var(--costimize_site_color);
    } */
}

:root {
  --slm_site_color: #1c344c;
  --slm_background_color: #f8f8f8;
  --slm_lite_site_color: #e7e7e7;
  --white-color: #fff;
}
.slm-sidebar,
.slm-sidebar {
  height: 100vh !important;
  position: fixed !important;
  border: none !important;
}

.slm-sidebar > .ps-sidebar-container {
  background-color: var(--slm_site_color) !important;
  padding-top: 16px;
  color: var(--white-color);
}

.mobile-slm-sidebar > .ps-sidebar-container {
  background-color: var(--slm_lite_site_color) !important;
  padding-top: 70px;
  color: var(--white-color);
}
.slm-sidebar > .ps-sidebar-container > .ps-menu-root {
  font-size: 20px !important;
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root {
  height: 65px !important;
}

.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button {
  margin-top: 6px;
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button:hover {
  color: var(--slm_site_color) !important;
  background-color: var(--slm_background_color);
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active {
  background-color: var(--slm_background_color) !important;
  color: var(--slm_site_color) !important;
  position: relative;
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root:hover {
  background-color: var(--slm_background_color) !important;
  color: var(--slm_lite_site_color) !important;
  position: relative;
}
/* .sidebar-budget-without-white:hover{
    display: none;
}
.sidebar-budget-with-white:hover{
    display: block;
} */
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -46px;
  height: 46px;
  width: 16px;
  left: 48px;
  border-top-right-radius: 25px;
  box-shadow: 0 -25px 0 0 var(--slm_background_color);
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 65px;
  height: 46px;
  width: 16px;
  left: 48px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 25px 0px 0px var(--slm_background_color);
}

/* compliance */
.card-header {
  width: 90%;
  text-align: center;
  border-bottom: none !important;
}
.card-options {
  width: 10%;
  text-align: end;
  margin-top: 4px;
}

.card-filter-icon {
  padding: 1px 10px;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 7px;
}

/* .initalresponse_table_width > .ant-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table-content {
  overflow-x: scroll !important;
  width:fit-content;
} */

/* .initalresponse_table_width > .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container {
  overflow-x: scroll !important;
} */

/* .ant-spin-nested-loading .ant-spin-container > .ant-table-wrapper .ant-table > .ant-table-wrapper .ant-table-container > .ant-table-content {
  overflow-x: scroll !important;
} */

.projectStatus-body-screen {
  margin-right: 4.6rem;
  padding: 0px 2.3rem;
  width: 100%;
  margin-bottom: 5rem;
}
.slm-sidebar,
.slm-sidebar {
  height: 100vh !important;
  position: fixed !important;
  border: none !important;
}

.slm-sidebar > .ps-sidebar-container {
  background-color: var(--slm_site_color) !important;
  padding-top: 16px;
  color: var(--white-color);
}

.mobile-slm-sidebar > .ps-sidebar-container {
  background-color: var(--slm_lite_site_color) !important;
  padding-top: 70px;
  color: var(--white-color);
}
.slm-sidebar > .ps-sidebar-container > .ps-menu-root {
  font-size: 20px !important;
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root {
  height: 65px !important;
}

.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button {
  margin-top: 6px;
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button:hover {
  color: var(--slm_site_color) !important;
  background-color: var(--slm_background_color);
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active {
  background-color: var(--slm_background_color) !important;
  color: var(--slm_site_color) !important;
  position: relative;
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root:hover {
  background-color: var(--slm_background_color) !important;
  color: var(--slm_lite_site_color) !important;
  position: relative;
}
/* .sidebar-budget-without-white:hover{
    display: none;
}
.sidebar-budget-with-white:hover{
    display: block;
} */
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -46px;
  height: 46px;
  width: 16px;
  left: 48px;
  border-top-right-radius: 25px;
  box-shadow: 0 -25px 0 0 var(--slm_background_color);
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 65px;
  height: 46px;
  width: 16px;
  left: 48px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 25px 0px 0px var(--slm_background_color);
}

/* compliance */
.card-header {
  width: 90%;
  text-align: center;
  border-bottom: none !important;
}
.card-options {
  width: 10%;
  text-align: end;
  margin-top: 4px;
}

.card-filter-icon {
  padding: 1px 10px;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 7px;
}

/* .initalresponse_table_width > .ant-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table-content {
  overflow-x: scroll !important;
  width:fit-content;
} */

/* .initalresponse_table_width > .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container {
  overflow-x: scroll !important;
} */

/* .ant-spin-nested-loading .ant-spin-container > .ant-table-wrapper .ant-table > .ant-table-wrapper .ant-table-container > .ant-table-content {
  overflow-x: scroll !important;
} */

.projectStatus-body-screen {
  margin-right: 4.6rem;
  padding: 0px 2.3rem;
  width: 100%;
  margin-bottom: 5rem;
}

tr.assetmanaged-table-footer > td {
  background: var(--site_color) !important;
  color: var(--white-color) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  border: none !important;
  white-space: nowrap;
}
.assetmanaged-table-footer {
  background: var(--site_color) !important;
  color: var(--white-color) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  border: none !important;
  text-align: center !important;
}

tr.assetmanaged-table-footer-new > td {
  background: #aec9ec !important;
  color: var(--white-color) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  border: none !important;
  text-align: center !important;
}

.assetmanaged-table-footer > .ant-typography {
  color: var(--white-color) !important;
}

.asset_managed_table > .ant-table-wrapper > .ant-table-summary {
  z-index: 0 !important;
}
/* tr.assetmanaged-table-footer-new > .ant-typography {
  color: var(--white-color) !important;
} */

/* .assetmanaged-table-footer td:first-child {
  border-bottom-left-radius: 10px;
}
.assetmanaged-table-footer td:last-child {
  border-bottom-right-radius: 10px;
} */

/* .ant-table-wrapper .ant-table thead th {
  color: var(--white-color) !important;
  background: var(--slm_site_color) !important;
  white-space: nowrap !important;
} */

.ant-table-wrapper .ant-table thead th:nth-child(4) {
  width: 4rem !important;
}

.ant-table-wrapper .ant-table thead th::before {
  display: none !important;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: none !important;

  padding: 10px 16px !important;
}

.ant-table-wrapper .ant-table tbody td {
  font-size: 12px !important;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: var(--white-color) !important;
  color: #000;
  font-weight: 400;
  font-size: 12px;
  line-height: 100% !important;
  border-bottom: 0.37px solid #848484 !important;
  text-align: left;
  white-space: nowrap;
  border: none;
  z-index: 0;
}

.role-table-icon {
  font-size: 18px;
  margin: 0px 4px;
}
.slm {
  width: 100% !important;
}

.slm.ant-table-wrapper .ant-table-thead > tr > th {
  color: var(--white-color) !important;
  background-color: var(--spp_site_color) !important;
  overflow-x: auto !important;
}
.slm.ant-table-wrapper .ant-table {
  background-color: var(--white-color) !important;
  color: var(--spp_site_color) !important;
  overflow-x: auto !important;
}

.slm.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: var(--slm_site_color) !important;
  color: var(--white-color) !important;
}
.slm-bg {
  background-color: var(--site_color);
}
.slm-color,
.slm-ul-li li,
.slm-ul-li li.active {
  color: var(--site_color);
}
.slm-ul-li li.active {
  color: var(--site_color);
  border-bottom: 2px solid var(--site_color);
}

.ant-picker.antd-date-picker {
  border-radius: 0.25rem;
  border-color: 1px solid #bdbdbd;
}
.sidemenu-toggle {
  background: var(--slm_site_color);
  color: #fff;
  width: 30px;
  height: 30px;
  padding: 3px;
  border-radius: 7px;
}

.slm .ant-table-header.ant-table-sticky-holder {
  z-index: 0;
}

.compliance-dot {
  height: 17px;
  width: 17px;
  background-color: #84aee7;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}
.non-compliance-dot {
  height: 17px;
  width: 17px;
  background-color: #ffeaa0;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}

.card-header-color {
  color: #848484;
}
.slm-addform{
  float: right !important;
}

.collapse-header .ant-collapse-header-text,
.collapse-header .ant-collapse-expand-icon span svg {
  color: var(--site_color);
  font-size: 18px;
  font-weight: 600;
}

.collapse-ui-list {
  list-style: disc;
  line-height: 2;
}

:root {
  --devops_site_color: #1c344c;
  --devops_background_color: #f8f8f8;
  --devops_lite_site_color: #e7e7e7;
  --white-color: #fff;
}

.header-title-devops {
  font-weight: 600;
  font-size: 22px;
  color: var(--devops_site_color);
  padding: 1rem 2rem 1rem 1rem;
}

.devops-background {
  margin-right: 4% !important;
  margin-left: 4% !important;
}

.center-image {
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
  width: 100% !important;
}

.center-text {
  display: flex;
  justify-content: center;
  color: #656565;
}

.devops_Table.ant-table-wrapper .ant-table-thead > tr > th {
  color: #fff !important;
  background-color: var(--devops_site_color) !important;
  text-align: center;
}

.devops_Table.ant-table-wrapper .ant-table {
  background-color: #fff !important;
  color: var(--devops_site_color) !important;
  overflow-x: auto !important;
}
.ant-table-wrapper {
  min-width: 100%;
}
.modal-btn {
  background-color: #fff !important;
  color: var(--devops_site_color) !important;
  border: none !important ;
}
/* .modal-icon {
  font-size: 18px !important;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6) !important;
} */

.table-col-status {
  width: 100px;
  height: 32px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dev-approve-button {
  color: #fff;
  width: 100px;
  height: 30px;
  border-radius: 5px;
  background: #3d9d1b;
  border: 2px solid #3d9d1b;
  margin: 0px 4px;
}
.reject-btn {
}
.approvel-view-cr {
  text-decoration: underline;
  cursor: pointer;
}
.dev-reject-button {
  color: #fff;
  width: 100px;
  height: 30px;
  border-radius: 5px;
  background: #be1e2d;
  border: 2px solid #be1e2d;
  margin: 0px 4px;
}

.modal-btn {
  display: flex;
  background-color: #fff !important;
  color: var(--devops_site_color) !important;
  border: none !important ;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6) !important;
  border-radius: 7px !important;
  cursor: pointer;
}
.modal-icon {
  font-size: 20px !important;
  color: var(--devops_site_color) !important;
}
.total-modal {
  display: flex;
  align-items: center;
}
.total-modal-text {
  display: flex;
  align-items: center;
  margin-left: 10px !important;
  margin-top: 2% !important;
}
.yes-btn {
  color: #fff !important;
  background-color: var(--devops_site_color) !important;
  border: none !important ;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6) !important;
  border-radius: 7px !important;
  float: left !important;
  cursor: pointer;
}
.no-btn {
  background-color: #fff !important;
  color: var(--devops_site_color) !important;

  border: none !important ;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6) !important;
  border-radius: 7px !important;
  float: right !important;
  cursor: pointer;
}
.modal-tag {
  color: var(--devops_site_color) !important;
}
.progress-text-line {
  padding: 0.25rem !important;
  font-size: 18px !important;
  text-align: right !important;
}
.title-btn {
  background-color: #fff !important;
  color: var(--devops_site_color) !important;
  border: none !important ;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6) !important;
  border-radius: 7px !important;
  padding: 5px;
  margin-bottom: 2%;
  width: 114px;
  cursor: pointer;
}
.title-text {
  color: var(--devops_site_color) !important;
  border: none !important ;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6) !important;
  border-radius: 7px !important;
  padding: 5px;
}
.head-text {
  padding: 8px;
  color: var(--devops_site_color) !important;
}
.tag-btn {
  width: 155px;
  text-align: center;
  cursor: pointer;
}
.progress-btn {
  border: none !important ;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6) !important;
  border-radius: 7px !important;
  width: 100% !important;
}
.cent-img {
  align-items: center !important;
  display: block;
  margin-left: 50%;
}

.devops-form-steps {
  background-color: white;
  left: 0;
  transform: translateX(0%);
  width: 60%;
}

.devops-form-steps.ant-steps.ant-steps-navigation .ant-steps-item::after {
  display: none;
  content: "";
}

.devops-form-steps.ant-steps.ant-steps-small .ant-steps-item-icon {
  display: none;
}

.basic-details {
  background-color: #d9dee3;
  border: none;
  padding: 5rem 0 5rem 0;
  margin-top: 6rem;
  border-radius: 12px;
}

.configuration-details {
  margin-top: 2rem;
  padding: 2rem 0 2rem 0;
}

.configuration-card-body {
  background-color: #d9dee3;
  padding: 3rem 0 3rem 0;
  border-radius: 12px;
}

.click-here-text {
  text-decoration: underline;
  color: var(--devops_site_color);
}

.additional-tag-modal .ant-modal-content,
.additional-tag-modal .ant-modal-header {
  background-color: #d9dee3;
}

.color-red {
  color: #be1e2d;
}

.sub-heading-devops-config {
  font-weight: 600;
  color: black;
}
